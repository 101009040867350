<script lang="ts">
  import { identity, startCase } from "lodash-es";
  import { api } from "../../../api";
  import { formatPhone } from "../../../formatPhone";
  import { getContacts } from "../../../getContacts";
  import { inflate } from "../../../inflate";
  import { property } from "../../../store";
  import { thumbnail } from "../../../utils/filepreview";
  import { sharedAccessSpaces } from "../../../utils/sharedaccess";
  import { by, vehicle } from "../../../utils/sorting";
  import Record from "../Record.svelte";
  import TableReport from "../TableReport.svelte";
  import Time from "../Time.svelte";
  import ValidRange from "../ValidRange.svelte";

  export let title, permitsFetch, group;

  let maxfiles = 1;

  $: columns = [
    {
      name: "Permit #",
      sort: (permit) => permit.media?.display,
      order: 0,
      overrideOrder: { space: 4 },
    },
    {
      name: "Type",
      sort: (permit) => permit.media?.description,
      order: 1,
      overrideOrder: { space: 5 },
    },
    {
      name: "Unit",
      sort: (permit) => permit.tenant?.display,
      order: 2,
      overrideOrder: { space: 3 },
    },
    {
      name: "Vehicle",
      sort: (permit) => permit.vehicle?.display,
      order: 3,
      overrideOrder: { space: 6 },
      comparer: vehicle,
    },
    {
      name: "Space",
      sort: (permit) => permit.spaces?.[0]?.display,
      order: 4,
      overrideOrder: { space: 0 },
    },
    {
      name: "EV",
      sort: (permit) => permit.spaces?.some((space) => space["capacity:charging"]) || 0,
      order: 5,
      overrideOrder: { space: 1 },
    },
    {
      name: "Size",
      sort: (permit) => size(permit.spaces) || "",
      order: 6,
      overrideOrder: { space: 2 },
    },
    {
      name: "Shared/Tandem",
      sort: (row) => !!row?.["access:shared"] | !!row?.spaces?.some((space) => space["access:shared"]),
      order: 7,
    },
    {
      name: "Name",
      sort: (permit) => permit.contacts?.map((c) => c.name).join("\n"),
      order: 8,
    },
    {
      name: "Email",
      sort: (permit) => permit.contacts?.map((c) => c.email).join("\n"),
      order: 9,
    },
    {
      name: "Phone",
      sort: (permit) => permit.contacts?.map((c) => c.phone).join("\n"),
      order: 10,
    },
    {
      name: "Notes",
      sort: (permit) => permit.description,
      order: 11,
    },
    { name: "Start", sort: (permit) => permit.valid?.min?.utc, order: 12 },
    {
      name: "Assigned By",
      sort: (permit) => permit.valid?.min?.by?.name,
      order: 13,
    },
    {
      name: "End",
      sort: (permit) => permit.valid?.max?.utc ?? "When Revoked",
      order: 14,
    },
    {
      name: "Revoked By",
      sort: (permit) => permit.valid?.max?.by?.name,
      order: 15,
    },
    {
      name: "Revoked On",
      sort: (permit) => permit.revoked?.datetime,
      order: 16,
    },
    {
      name: "Revoked Reason",
      sort: (permit) => permit.revoked?.reason,
      order: 17,
    },
    {
      name: "Fees",
      sort: (permit) => permit.fees?.reduce?.((acc, val) => acc + val.total.value, 0),
      order: 18,
    },
    {
      name: "Photos",
      count: maxfiles,
      //sort: (row) => row.files?.length, order: 14
    },
     {
      name: "ID",
      //sort: (row) => row.files?.length, order: 14
    },
  ].sort(by((x) => x.overrideOrder?.[group] ?? x.order));

  $: extraDataFetch = loadExtraData($property.id);

  async function loadExtraData(propertyId) {
    const now = new Date().toISOString();

    const query = {
      scope: propertyId,
      viewpoint: now,
      valid: `${now}/${now}`,
    };

    const [mediaPayload, spacesPayload, tenantsPayload] = await Promise.all([
      api.get("media", query).then(inflate),
      api.get("spaces", query).then(inflate),
      api.get(`units/tenants`, query).then(inflate),
    ]);

    return {
      media: Object.values(mediaPayload?.media?.items ?? {}),
      spaces: Object.values(spacesPayload?.spaces?.items ?? {}),
      tenants: Object.values(tenantsPayload?.tenants?.items ?? {}),
    };
  }

  function groupShouldInclude(permit, group) {
    switch (group) {
      case "media":
        return !!permit.media;
      case "space":
        return !!permit.space;
    }
    return true;
  }

  function getRows(json, group, { media, spaces, tenants }) {
    const rows = Object.values(json.permits.items)
      .filter((x) => x.assigned && groupShouldInclude(x, group))
      .map((permit) => {
        return {
          ...permit,
          files: Object.entries(json.attachments.items[permit.id] || {}).reduce((result, [id, type]) => {
            if (type != "file") return result;
            //if (!json.items[id]) return result;
            result.push(json.items[id]);
            return result;
          }, []),
          fees: Object.values(permit.fees ?? {}),
          contacts: getContacts(permit, json).sort(by("name")),
          //notes: Object.values(permit.notes ?? {}).sort(by((x) => x.issued.utc)),
        };
      })
      .sort(
        by(
          (x) => x.valid?.from ?? new Date("0001-12-25").toISOString(),
          (x) => x.valid?.to ?? new Date("9999-12-31").toISOString()
        )
      );

    maxfiles = rows.reduce((count, r) => Math.max(count, r.files.length), maxfiles);

    if (!group || group.toLowerCase() === "media") {
      const usedMedia = new Set(rows.map((r) => r.media.id));
      rows.push(
        ...media
          .filter((x) => !usedMedia.has(x.id))
          .map((media) => ({
            media,
          }))
      );
    }
    if (!group || group.toLowerCase() === "tenant") {
      const usedTenants = new Set(rows.flatMap((r) => (r.tenant ? [r.tenant.id] : [])));
      rows.push(
        ...tenants
          .filter((x) => !usedTenants.has(x.id))
          .map((tenant) => ({
            tenant,
          }))
      );
    }
    if (!group || group.toLowerCase() === "space") {
      const usedSpaces = new Set(rows.flatMap((r) => r.spaces?.map((s) => s.id) ?? []));
      rows.push(...spaces.filter((x) => !usedSpaces.has(x.id)).map((space) => space));
    }

    rows.forEach((row) => {
      if (row.spaces?.length) {
        row.spaces.forEach((space, i) => {
          if (space["access:shared"] && typeof space["access:shared"] === "string") {
            row.spaces[i]["access:shared"] = sharedAccessSpaces(spaces, row.spaces[i]["access:shared"]);
          }
        });
      }
      if (row["access:shared"] && typeof row["access:shared"] === "string") {
        row["access:shared"] = sharedAccessSpaces(spaces, row["access:shared"]);
      }
    });

    return rows;
  }

  function size(spaces) {
    if (!spaces) return null;
    let sizes = [];
    spaces.forEach((space) => {
      if (space.parking_space === "motorcycle" && !sizes.includes("motorcycle")) sizes.push("motorcycle");
      else if (space.size && !sizes.includes(space.size)) sizes.push(space.size);
    });
    if (sizes.length > 1) return null;
    return sizes[0];
  }

  function sharedSpacesSet(spaces, multispace) {
    let set = new Set();
    if (multispace) {
      spaces.forEach((space) => {
        space["access:shared"]?.forEach((sharedSpace) => set.add(sharedSpace));
      });
    } else {
      spaces.forEach((sharedSpace) => set.add(sharedSpace));
    }
    return [...set];
  }
</script>

{#await Promise.all([permitsFetch, extraDataFetch])}
  <TableReport {title} loading />
{:then [permitsPayload, extraData]}
  <TableReport
    {title}
    {columns}
    rows={getRows(permitsPayload, group, extraData)}
    valid={permitsPayload.permits.valid}
    generated={permitsPayload.generated}>
    <tr slot="row" let:row let:rowNumber>
      <td role="presentation">
        {rowNumber}
      </td>
      {#if group == "space"}
        {#if row.spaces}
          <th scope="row">
            <h1>
              {#each row.spaces as space}
                <Record item={space} />
              {/each}
            </h1>
          </th>
          <td>
            {#if row.spaces.some((space) => space?.["capacity:charging"])}
              <data class="capacity-charging" value="yes">EV</data>
            {/if}
          </td>
          <td>
            {#if row.spaces?.some((space) => space.size)}
              <data class="size" value={size(row.spaces)}>{startCase(size(row.spaces))}</data>
            {/if}
          </td>
        {:else}
          <th scope="row">
            <h1>
              <Record item={row} />
            </h1>
          </th>
          <td />
          <td />
        {/if}
        <td>
          {#if row.tenant}
            <h1>
              <Record item={row.tenant} />
            </h1>
          {/if}
        </td>
        <td>
          {#if row.media}
            <h1>
              <Record item={row.media} />
            </h1>
          {/if}
        </td>
        <td>
          {#if row.media}
            <ul class="notes">
              <li class="note">
                {row.media.description}
              </li>
            </ul>
          {/if}
        </td>
        <td>
          {#if row.vehicle}
            <h1>
              <Record item={row.vehicle} />
            </h1>
          {/if}
        </td>
        <td>
          {#if row.spaces}
            {#each sharedSpacesSet(row.spaces, true) as sharedSpace}
              {#if sharedSpace?.["access:shared"]}
                <Record item={sharedSpace} />
              {/if}
            {/each}
          {:else if row["access:shared"]}
            {#each sharedSpacesSet(row["access:shared"], false) as sharedSpace}
              <Record item={sharedSpace} />
            {/each}
          {/if}
        </td>
      {:else}
        <th scope="row">
          {#if row.media}
            <h1>
              <Record item={row.media} />
            </h1>
          {/if}
        </th>
        <td>
          {#if row.media}
            <ul class="notes">
              <li class="note">
                {row.media.description}
              </li>
            </ul>
          {/if}
        </td>
        <td>
          {#if row.tenant}
            <h1>
              <Record item={row.tenant} />
            </h1>
          {/if}
        </td>
        <td>
          {#if row.vehicle}
            <h1>
              <Record item={row.vehicle} />
            </h1>
          {/if}
        </td>
        <td>
          {#if row.spaces}
            {#each row.spaces as space}
              <h1><Record item={space} /></h1>
            {/each}
          {:else if row.type === "space"}
            <h1><Record item={row} /></h1>
          {/if}
        </td>
        <td>
          {#if row.spaces?.some((space) => space?.["capacity:charging"])}
            <data class="capacity-charging" value="yes">EV</data>
          {/if}
        </td>
        <td>
          {#if row.spaces?.some((space) => space.size)}
            <data class="size" value={size(row.spaces)}>{startCase(size(row.spaces))}</data>
          {/if}
        </td>
        <td>
          {#if row.spaces}
            {#each sharedSpacesSet(row.spaces, true) as sharedSpace}
              {#if sharedSpace?.["access:shared"]}
                <Record item={sharedSpace} />
              {/if}
            {/each}
          {:else if row["access:shared"]}
            {#each sharedSpacesSet(row["access:shared"], false) as sharedSpace}
              <Record item={sharedSpace} />
            {/each}
          {/if}
        </td>
      {/if}
      <td>
        <ul class="notes">
          {#each row.contacts || [] as contact (contact.uuid)}
            <li class="contact">{contact.name ?? ""}</li>
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts || [] as contact (contact.uuid)}
            {#if contact.email}
              <li class="contact">
                <a href="mailto:{contact.email}">
                  {contact.email}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each row.contacts || [] as contact (contact.uuid)}
            {#if contact.phone}
              <li class="contact">
                <a href="tel:{contact.phone}">
                  {formatPhone(contact.phone)}
                </a>
              </li>
            {/if}
          {/each}
        </ul>
      </td>
      <td>
        <ul class="notes">
          {#each [row.description].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td>
        {#if row.valid?.min}
          <Time time={row.valid.min.datetime} timezone={row.timezone} />
        {/if}
      </td>
      <td>
        {#if row.valid?.min?.by}
          <h1>
            <data class="user">
              {row.valid.min.by.name ?? ""}
            </data>
          </h1>
        {/if}
      </td>
      <td>
        {#if row.valid?.max}
          <Time time={row.valid.max.datetime} timezone={row.timezone} />
        {:else}
          <time>When Revoked</time>
        {/if}
      </td>
      <td>
        {#if row.valid?.max?.by}
          <h1>
            <data class="user">
              {row.valid.max.by.name ?? ""}
            </data>
          </h1>
        {/if}
      </td>
      <td>
        {#if row.revoked?.datetime}
          <Time time={row.revoked.datetime} timezone={row.timezone} />
        {/if}
      </td>
      <td>
        <ul class="notes">
          {#each [row.revoked?.reason].filter(identity) as text}
            <li class="note">{text}</li>
          {/each}
        </ul>
      </td>
      <td>
        {#if row.fees && row.fees.length > 0}
          <ul>
            {#each row.fees as fee}
              <li>{fee.total.display}</li>
            {/each}
          </ul>
        {/if}
      </td>
      {#if (row.files?.length || 0) > 0}
        {#each row.files as file}
          <td>
            <ul class="photos files">
              <li class="file {file.type.split('/').join(' ')}">
                <figure>
                  <a href={file.url} target="_blank">
                    <img width="44" height="44" alt="Violation" src={thumbnail(file.url, 80, 80, true)} />
                  </a>
                </figure>
              </li>
            </ul>
          </td>
        {/each}
      {/if}
      {#if (row.files?.length || 0) < maxfiles}
        <td colspan={maxfiles - (row.files?.length || 0)} />
      {/if}
      <td>{#if row.id}<data value="{row.id}">{row.id}</data>{/if}</td>
    </tr>

    <span slot="controls"><ValidRange /></span>
  </TableReport>
{:catch error}
  <TableReport {title} {error} />
{/await}
