import { identity } from "lodash-es";

export function thumbnail(fileOrUrl, width, height, crop) {
  var url = new URL(fileOrUrl.url || fileOrUrl);
  var pdf = fileOrUrl.format == "application/pdf" || url.pathname.includes(".pdf");
  var heic = fileOrUrl.format == "image/heic" || url.pathname.includes(".heic");
  if (pdf || heic) {
    url.host = "property-content.imgix.net";
    var query = url.searchParams;
    query.append("auto", "compress,format");
    if (width) query.append("w", width);
    if (height) query.append("h", height);
    if (crop) query.append("fit", "crop");
    //url.searchParams = query;
    return url.toString();
  }

  return (
    "https://upload.parkingboss.com/cdn-cgi/image/" +
    [width && `w=${width}`, height && `h=${height}`, crop && `fit=cover`, "format=auto"].filter(identity).join(",") +
    "/" +
    url
  );
}
